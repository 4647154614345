import React from 'react';
import { Form } from '../Form'
import { User, Popup } from '../../api/Core'

export default props => {
    
    return (
        <Form id={"signupForm"} form={{
            logUsername: {
                placeholder: "Логин",
                validate: {
                    presence: true,
                    length: {
                        minimum: 3,
                        maximum: 20
                    },
                    format: {
                        pattern: "[a-z0-9]+",
                        flags: "i",
                    }
                }
            },
            logPassword: {
                placeholder: "Пароль",
                type: "password",
                validate: {
                    presence: true,
                    length: {
                        minimum: 8
                    }
                }
            },
        }}
        actions={[
            {
                title: "Войти",
                validate: true,
                callback: (formData) => {
                    return new Promise((resolve, reject) => {
                        User.login(formData)
                        .then(res => {
                            console.log(res)
                            if(!res.success) return resolve(res)
                            Popup.close()
                            return resolve(res)
                        })
                    })
                }
            }
        ]} />

    )
}