import React, {useState} from 'react';
import { Popup, L, getUserLanguage } from '../../api/Core'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const _BasketItem = props => {
    const [amount, setAmount] = useState(props.amount)
    const basePrice = props.product.price *  props.amount;
    const optPrice  = props.product.optprice *  props.amount;
    const handleAmountChange = (e, id) => {
        e.target.validity.valid && setAmount(+e.target.value)
        const a = (isNaN(+e.target.value) || +e.target.value < 1 ? 1 : +e.target.value > 9999 ? 9999 : +e.target.value)
        props.basketSet({id: id, amount: a})
    }

    const handleAmount = (val, id) => {
        const a = (isNaN(val) || val < 0 ? 0 : val > 9999 ? 9999 : val)
        props.basketSet({id: id, amount: a})
        setAmount(+a)
    }

    const name = props.product["name_" + getUserLanguage().toLowerCase()]

    return (
        <div className="BasketItem" style={{transform: `translateY(${props.pos * 100}%)`}}>
            <div className="ImgWrapper">
                <img src={props.product.img} alt={name} />
            </div>
            <div className="NameWrapper">{name}</div>
            <div className="AmountChanger">
                <button onClick={() => {
                    setAmount(+Math.max(amount - 1, 1))
                    props.basketMinus({id: props.product.id, amount: 1})
                }}>-</button>
                <input 
                    value={props.amount}
                    pattern = "^[0-9]*([.][0-9]*)?$"
                    onChange={ e => handleAmountChange(e, props.product.id) } 
                    onBlur={ e => handleAmount(e.target.value, props.product.id) } 
                />
                <button onClick={() => {
                    setAmount(+Math.min(amount + 1, 9999))
                    props.basketPlus({id: props.product.id, amount: 1})
                }}>+</button>
            </div>
            <div className="RemoveEl"  onClick={() => props.basketSet({id: props.product.id, amount: 0})}>✖</div>
            <div className="OptionToggle">
                <span>{L("ui_екфн")} { props.amount} {L("ui_pc")}</span>
            </div>
            <div className="PriceWrapper">
                <span className="Price"><span>{amount < props.product.optfrom ? basePrice : optPrice}</span> {L("ui_hrn")}</span>
                <span className="Deal">{L("ui_sale")} {amount < props.product.optfrom ? 0 : basePrice - optPrice } {L("ui_hrn")}</span>
            </div>
        </div>
    )
}

function MapDispatchToProps(dispatch) {
    return bindActionCreators({
        basketPlus: (data) => {
            return {
                type: 'BASKET_PLUS',
                payload: data
            }
        },
        basketMinus: (data) => {
            return {
                type: 'BASKET_MINUS',
                payload: data
            }
        },
        basketSet: (data) => {
            return {
                type: 'BASKET_SET',
                payload: data
            }
        }
    }, dispatch)
}

const BasketItem = connect(() => {}, MapDispatchToProps)(_BasketItem)

const BasketPopup = props => {
    const totalPrice = Object.keys(props.basket).reduce((prev, curr) => {
        const amount = props.basket[curr];
        const product = props.products.filter(p => p.id === curr)[0];
        const basePrice = product.price * amount;
        const optPrice = product.optprice * amount;
        return prev + (props.basket[curr] < product.optfrom ? basePrice : optPrice)
    }, 0)
    
    return (
        <div className="BasketPopup">
            <div className="Prods">
                {Object.keys(props.basket).sort((a, b) => {
                    if (a < b) {
                        return 1;
                    }
                    if (a > b) {
                        return -1;
                    }
                    return 0;
                    }).map((key, i) => {
                    return props.products.filter(p => p.id === key).length ? <BasketItem product={props.products.filter(p => p.id === key)[0]} pos={i} amount={+props.basket[key]} basket={props.basket} /> : null
                })}
            </div>
            <div className="Controls">
                <button className="alt" onClick={() => {Popup.close()}}>{L("ui_continue_shopping")}</button>
                <button style={{
                    opacity: totalPrice > 0 ? 1 : .5,
                    cursor: totalPrice  > 0 ? "pointer" : "not-allowed"
                }} onClick={() => { 
                   totalPrice > 0 && Popup.flip({
                    type: "order",
                    opts: {
                        sum: totalPrice
                    }
                })}}>{L("ui_place_order")}</button>
            </div> 
        </div>
    )
}

function MapStateToProps(state) {
    return {
        products: state.products,
        basket: state.basket,
    }
}

export default connect(MapStateToProps)(BasketPopup);