import React from 'react';
import { connect } from 'react-redux';
import { Form } from '../Form'
import { Popup, AD, payOrder, L } from "../../api/Core"

const OrderPopup = props => {
    return (
        <div className="OrderPopup">
            <Form id={"addProdForm"} form={{
                orderName: {
                    placeholder: L("order_name"),
                    validate: {
                        presence: true,
                        format: {
                            pattern: ".{1,} .{1,} .{1,}",
                            flags: "i",
                            message: L("order_name_err")
                        }
                    }
                },
                orderTel: {
                    placeholder: L("form_tel"),
                    class: "split2",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 16,
                            message: L("form_tel_err")
                        },
                    }
                },
                orderEmail: {
                    placeholder: "Email",
                    class: "split2",
                    validate: {
                        presence: true,
                        email: {
                            message: L("form_email_err")
                        }
                    }
                },
                orderAdress: {
                    placeholder: L("form_adress"),
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 100,
                            message: L("form_adress_err")
                        },
                    }
                },
            }} 
            actions={[
                {
                    // title: "Перейти к оплате ( " + props.opts.sum + " грн. )",
                    title: L("order_confirm"),
                    class: "alt",
                    validate: true,
                    callback: (formData) => {
                        return new Promise((resolve, reject) => {
                            payOrder(formData)
                            .then(res => {
                                if(!res.success) return resolve(res)
                                AD("BASKET_CLEAR")
                                Popup.flip({
                                    type: "statusMessage",
                                    opts: {
                                        statusType: "OrderPlaced"
                                    }
                                })
                                return resolve(res)
                            })
                        })
                    }
                }
            ]}
            />
        </div>
    )
}

function MapStateToProps(state) {
    return {
        products: state.products,
        basket: state.basket,
    }
}

export default connect(MapStateToProps)(OrderPopup);