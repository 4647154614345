import React, { useRef } from 'react';
import { User } from '../api/Core'
import { Form } from './Form'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


const TextEditor = ({texts, prop}) => {
    const R1 = useRef();
    const R2 = useRef();
    const R3 = useRef();


    return (
    <div className="TextEditor">
        <div className="Bl">
            <div className="T">
                <label>UA</label>
                <textarea ref={R1} defaultValue={texts[prop].UA} />
            </div>
            <div className="T">
                <label>RU</label>
                <textarea ref={R2} defaultValue={texts[prop].RU} />
            </div>
            <div className="T">
                <label>EN</label>
                <textarea ref={R3} defaultValue={texts[prop].EN} />
            </div>
            <button onClick={() => {
                User.editText({
                    type: prop,
                    data: {
                        UA: R1.current.value,
                        RU: R2.current.value,
                        EN: R3.current.value,
                    }
                })
            }}>Сохранить</button>
        </div>
    </div>
    )
}

const ContactEditor = ({contact}) => {
    return (
    <div className="TextEditor">
        <Form id={"editContacts"} form={{
            tel: {
                placeholder: "Номер телефона",
                class: "split3",
                default: contact.tel,
                validate: {
                    presence: true,
                    length: {
                        minimum: 3,
                        maximum: 50
                    },
                }
            },
            fax: {
                placeholder: "Факс",
                class: "split3",
                default: contact.fax,
                validate: {
                    presence: true,
                    length: {
                        minimum: 3,
                        maximum: 50
                    },
                }
            },
            mail: {
                placeholder: "Электронная почта",
                class: "split3",
                default: contact.mail,
                validate: {
                    presence: true,
                    length: {
                        minimum: 3,
                        maximum: 50
                    },
                }
            },
        }}
        actions={[
            {
                title: "Сохранить",
                validate: false,
                callback: (formData) => {
                    return new Promise((resolve, reject) => {
                        User.editText({
                            type: "contact",
                            data: formData
                        }).then(res => {
                            return resolve(res)
                        })
                    })
                }
            }
        ]}/>
    </div>
    )
}

const Admin = props => {
    console.log(props)
    return (
        <div className="App Admin">
            <div className="AdminHeader">
                <p>Админ-панель</p>
                <button onClick={() => User.logout()}>Выйти</button>
            </div>
            <div className="Row">
                <div className="AdminProds">
                    <h3> знак "|" в описаниях – перевод строки</h3>
                    <Form id={"addProdForm"} form={{
                prodName_ua: {
                    placeholder: "Название товара UA",
                    class: "split3",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 30,
                            message: "err"
                        },
                    }
                },
                prodName_ru: {
                    placeholder: "Название товара RU",
                    class: "split3",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 30,
                            message: "err"
                        },
                    }
                },
                prodName_en: {
                    placeholder: "Название товара EN",
                    class: "split3",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 30,
                            message: "err"
                        },
                    }
                },
                prodImg: {
                    placeholder: "Ссылка на изображение",
                    validate: {
                        presence: true,
                    }
                },
                prodPrice: {
                    placeholder: "Цена",
                    class: "split3",
                    validate: {
                        presence: true,
                        numericality: {
                            onlyInteger: true,
                            greaterThan: 1
                        }
                    }
                },
                prodOptPrice: {
                    placeholder: "Цена (опт)",
                    class: "split3",
                    validate: {
                        presence: true,
                        numericality: {
                            onlyInteger: true,
                            greaterThan: 1
                        }
                    }
                },
                prodOptFrom: {
                    placeholder: "Опт от шт.",
                    class: "split3",
                    validate: {
                        presence: true,
                        numericality: {
                            onlyInteger: true,
                            greaterThan: 1
                        }
                    }
                },
                prodDesc_ua: {
                    placeholder: "Описание товара UA",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 100
                        },
                    }
                },
                prodDesc_ru: {
                    placeholder: "Описание товара RU",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 100
                        },
                    }
                },
                prodDesc_en: {
                    placeholder: "Описание товара EN",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 100
                        },
                    }
                },
            }} 
            actions={[
                {
                    title: "Добавить товар",
                    class: "alt",
                    validate: true,
                    callback: (formData) => {
                        return new Promise((resolve, reject) => {
                            User.addProduct(formData)
                            .then(res => {
                                console.log(res)
                                if(!res.success) return resolve(res)
                                    window.location.reload()
                                return resolve(res)
                            })
                        })
                    }
                }
            ]}
            />
                    {props.products.sort((a, b) => {
                        if (a.added < b.added) {
                            return 1;
                        }
                        if (a.added > b.added) {
                            return -1;
                        }
                        return 0;
                        }).map(el => (
                        <div key={"ADMPRD"+ el.id} className="Product">
                            <Form id={"addProdForm"} form={{
                                prodName_ua: {
                                        placeholder: "Название товара UA",
                                        class: "split3",
                                        default: el.name_ua,
                                        validate: {
                                            presence: true,
                                            length: {
                                                minimum: 3,
                                                maximum: 30,
                                                message: "err"
                                            },
                                        }
                                    },
                                    prodName_ru: {
                                        placeholder: "Название товара RU",
                                        class: "split3",
                                        default: el.name_ru,
                                        validate: {
                                            presence: true,
                                            length: {
                                                minimum: 3,
                                                maximum: 30,
                                                message: "err"
                                            },
                                        }
                                    },
                                    prodName_en: {
                                        placeholder: "Название товара EN",
                                        class: "split3",
                                        default: el.name_en,
                                        validate: {
                                            presence: true,
                                            length: {
                                                minimum: 3,
                                                maximum: 30,
                                                message: "err"
                                            },
                                        }
                                    },
                                prodImg: {
                                    placeholder: "Ссылка на изображение",
                                    default: el.img,
                                    validate: {
                                        presence: true,
                                    }
                                },
                                prodPrice: {
                                    placeholder: "Цена",
                                    class: "split3",
                                    default: el.price,
                                    validate: {
                                        presence: true,
                                        numericality: {
                                            onlyInteger: true,
                                            greaterThan: 1
                                        }
                                    }
                                },
                                prodOptPrice: {
                                    placeholder: "Цена (опт)",
                                    class: "split3",
                                    default: el.optprice,
                                    validate: {
                                        presence: true,
                                        numericality: {
                                            onlyInteger: true,
                                            greaterThan: 1
                                        }
                                    }
                                },
                                prodOptFrom: {
                                    placeholder: "Опт от шт.",
                                    class: "split3",
                                    default: el.optfrom,
                                    validate: {
                                        presence: true,
                                        numericality: {
                                            onlyInteger: true,
                                            greaterThan: 1
                                        }
                                    }
                                },
                                prodDesc_ua: {
                                        placeholder: "Описание товара UA",
                                        default: el.details_ua,
                                        validate: {
                                            presence: true,
                                            length: {
                                                minimum: 3,
                                                maximum: 100
                                            },
                                        }
                                    },
                                    prodDesc_ru: {
                                        placeholder: "Описание товара RU",
                                        default: el.details_ru,
                                        validate: {
                                            presence: true,
                                            length: {
                                                minimum: 3,
                                                maximum: 100
                                            },
                                        }
                                    },
                                    prodDesc_en: {
                                        placeholder: "Описание товара EN",
                                        default: el.details_en,
                                        validate: {
                                            presence: true,
                                            length: {
                                                minimum: 3,
                                                maximum: 100
                                            },
                                        }
                                    },
                                prodID: {
                                    type: "hidden",
                                    default: el.id,
                                },
                            }}
                            actions={[
                                {
                                    title: "Сохранить",
                                    validate: true,
                                    callback: (formData) => {
                                        return new Promise((resolve, reject) => {
                                            User.updateProduct(formData)
                                            .then(res => {
                                                if(!res.success) return resolve(res)
                                                alert("Успешно!")
                                                return resolve(res)
                                            })
                                        })
                                    }
                                },
                                {
                                    title: "Удалить",
                                    validate: false,
                                    callback: (formData) => {
                                        return new Promise((resolve, reject) => {
                                            User.deleteProduct(formData.prodID)
                                            .then(res => {
                                                if(!res.success) return resolve(res)
                                                // alert("Успешно!")
                                                return resolve(res)
                                            })
                                        })
                                    }
                                },
                            ]}/>
                        </div>
                    ))}
                </div>
                <div className="EditorsWrapper">
                    {props.appState.loaded && <>
                        <h2 className="_BlockTitle">Текст главного блока</h2>
                        <TextEditor prop={"main"} texts={props.appState.texts} />
                    </>}
                    {props.appState.loaded && <>
                        <h2 className="_BlockTitle">Текст блока партнеров</h2>
                        <TextEditor prop={"partner"} texts={props.appState.texts} />
                    </>}
                    {props.appState.loaded && <>
                        <h2 className="_BlockTitle">Редактор контактов</h2>
                        <ContactEditor contact={props.appState.texts.contact} />
                        </>
                    } 
                </div>
            </div>
        </div>
    )
}

function MapStateToProps(state) {
    return {
        appState: state.app_state,
        products: state.products,
        basket: state.basket,
    }
}


function MapDispatchToProps(dispatch) {
    return bindActionCreators({
        basketPlus: (data) => {
            return {
                type: 'BASKET_PLUS',
                payload: data
            }
        },
        basketMinus: (data) => {
            return {
                type: 'BASKET_MINUS',
                payload: data
            }
        },
        basketSet: (data) => {
            return {
                type: 'BASKET_SET',
                payload: data
            }
        }
    }, dispatch)
}

export default connect(MapStateToProps, MapDispatchToProps)(Admin);