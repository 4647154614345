import React from 'react';
import { L } from "../../api/Core";

export default props => {
    return (
        <div className="StatusMessagePopup">
            <img src="https://img.icons8.com/wired/128/ffffff/ok.png" alt="" />
            <h2>{L("order_success")}</h2>
            <h3>{L("manager_note")}</h3>
            <button onClick={() => props.close()}>{L("status_close")}</button>
        </div>
    )
}