import React from 'react'
import '../assets/sass/flip.sass';

export default props => {
    if(props.children.length !== 2) throw new Error("Flip component must get exactly tho children!")
    const type = (props.type === "v" ? "v " : " ");
    return (
        <div 
            onClick={props.onClick || (() => {})}
            className={"FlipWrapper " + type + (props.flipped ? "flipped " : " " + (props.className || "") )}
            style={props.style || {}}
        >
            <div className="Flip">
                <div className="Front">{props.children[0]}</div>
                <div className="Back"> {props.children[1]}</div>
            </div>
        </div>
    )
}