import React, {useState} from 'react';
import '../assets/sass/header.sass';
import MobileMenu from './MobileMenu'
import logo from "../assets/img/logo1.png"
import { Popup, getUserLanguage, L } from '../api/Core'
import { ReactComponent as BasketIcon } from "../assets/svg/shopping-cart.svg"
import { setCookie } from "../helpers/Cookie"

const topMenu = [
  {title: L("menu_contact"),  url: "#cont"},
  {title: L("menu_products"),  url: "#prod"},
  {title: L("menu_main"),  url: "/"},
]


const changeLanguage = (lang) => {
  if(lang === "RU" || lang === "UA" || lang === "EN")
  setCookie("lang", lang, 999)
  window.location.reload()
}
const currentLanguage = getUserLanguage();

export default props => {
  const [MobileMenuOpened, SetMobileMenuOpened] = useState(false);
  const basketCount = Object.keys(props.basket).reduce((a, n) => a + props.basket[n], 0)
    return (
        <>
          <header className="MainHeader h">
            <img 
              src={logo}
              alt="Logo"
              className="Logo"
              />
            <div className="MainMenu">
              {topMenu.map(item => (
                <a href={item.url} key={"TPMN"+ item.title}>{item.title}</a>
              ))}
              <div className="Language">
                <button 
                  disabled={currentLanguage === "RU"} 
                  className={currentLanguage === "RU" ? "active" : ""}
                  onClick={() => changeLanguage("RU")}
                >RU</button>
                <button 
                  disabled={currentLanguage === "UA"} 
                  className={currentLanguage === "UA" ? "active" : ""}
                  onClick={() => changeLanguage("UA")}
                >UA</button>
                <button 
                  disabled={currentLanguage === "EN"} 
                  className={currentLanguage === "EN" ? "active" : ""}
                  onClick={() => changeLanguage("EN")}
                >EN</button>
              </div>
              <div onClick={() => Popup.open({type: "basket"})} className="BasketToggle">
                <p className={"BasketCount " + (basketCount ? "active" : "")}>{basketCount}</p>
                <BasketIcon />
              </div>
            </div>
                {/* <span
                  className="MobileMenuToggle"
                  src="../assets/img/logo1.png"
                  alt="menu"
                  onClick = {() => SetMobileMenuOpened(true)}
                >mobile menu</span> */}
          </header>
          <MobileMenu  menu={topMenu} status={MobileMenuOpened} setStatus={SetMobileMenuOpened} />
        </>
    );
}
