import { combineReducers } from 'redux';
import popup from './popup-reducer'
import products from './products-reducer'
import basket from './basket-reducer'
import user from './user-reducer'
import app_state from './app-state-reducer'


const rootReducer = combineReducers({
    popup,
    products,
    basket,
    user,
    app_state
})

export default rootReducer;