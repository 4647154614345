import React from 'react';


export default props => {
    return (
        <div className={"Preloader " + (true ? "loaded" : "") }>
            {/* <img src="/svg/123.svg" alt="" /> */}
            <div className="load">
              <hr/><hr/><hr/><hr/>
            </div>
        </div>
    )
}