import React from 'react';
import { Popup, User } from '../../api/Core'
import { Form } from '../Form'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';



const AdminPopup = props => {
    return (
        <div className="AdminPopup">
            <div className="AdminProds">
                {props.products.sort((a, b) => {
                    if (a.added < b.added) {
                        return 1;
                    }
                    if (a.added > b.added) {
                        return -1;
                    }
                    return 0;
                    }).map(el => (
                    <div key={"ADMPRD"+ el.id} className="Product">
                        <Form id={"addProdForm"} form={{
                            prodName: {
                                placeholder: "Название товара",
                                default: el.name,
                                validate: {
                                    presence: true,
                                    length: {
                                        minimum: 3,
                                        maximum: 30
                                    },
                                }
                            },
                            prodImg: {
                                placeholder: "Ссылка на изображение",
                                default: el.img,
                                validate: {
                                    presence: true,
                                    url: true
                                }
                            },
                            prodPrice: {
                                placeholder: "Цена",
                                class: "split3",
                                default: el.price,
                                validate: {
                                    presence: true,
                                    numericality: {
                                        onlyInteger: true,
                                        greaterThan: 1
                                    }
                                }
                            },
                            prodOptPrice: {
                                placeholder: "Цена (опт)",
                                class: "split3",
                                default: el.optprice,
                                validate: {
                                    presence: true,
                                    numericality: {
                                        onlyInteger: true,
                                        greaterThan: 1
                                    }
                                }
                            },
                            prodOptFrom: {
                                placeholder: "Опт от шт.",
                                class: "split3",
                                default: el.optfrom,
                                validate: {
                                    presence: true,
                                    numericality: {
                                        onlyInteger: true,
                                        greaterThan: 1
                                    }
                                }
                            },
                            prodDesc: {
                                placeholder: "Описание товара ( | – перевод строки )",
                                default: el.details,
                                validate: {
                                    presence: true,
                                    length: {
                                        minimum: 3,
                                        maximum: 50
                                    },
                                }
                            },
                            prodID: {
                                type: "hidden",
                                default: el.id,
                            },
                        }}
                        actions={[
                            {
                                title: "Сохранить",
                                validate: true,
                                callback: (formData) => {
                                    return new Promise((resolve, reject) => {
                                        User.updateProduct(formData)
                                        .then(res => {
                                            if(!res.success) return resolve(res)
                                            alert("Успешно!")
                                            return resolve(res)
                                        })
                                    })
                                }
                            },
                            {
                                title: "Удалить",
                                validate: false,
                                callback: (formData) => {
                                    return new Promise((resolve, reject) => {
                                        User.deleteProduct(formData.prodID)
                                        .then(res => {
                                            if(!res.success) return resolve(res)
                                            // alert("Успешно!")
                                            return resolve(res)
                                        })
                                    })
                                }
                            },
                        ]}/>
                    </div>
                ))}
            </div>
            <div className="Controls">
                <button className="alt" onClick={() => {
                    User.logout();
                    Popup.close();
                }}>Покинуть панель</button>
                <button onClick={() => { Popup.flip({
                    type: "addproduct"
                })}}>Добавить продукт</button>
                <button onClick={() => { Popup.flip({
                    type: "edittext"
                })}}>Править текст</button>
            </div> 
        </div>
    )
}

function MapStateToProps(state) {
    return {
        products: state.products,
        basket: state.basket,
    }
}


function MapDispatchToProps(dispatch) {
    return bindActionCreators({
        basketPlus: (data) => {
            return {
                type: 'BASKET_PLUS',
                payload: data
            }
        },
        basketMinus: (data) => {
            return {
                type: 'BASKET_MINUS',
                payload: data
            }
        },
        basketSet: (data) => {
            return {
                type: 'BASKET_SET',
                payload: data
            }
        }
    }, dispatch)
}

export default connect(MapStateToProps, MapDispatchToProps)(AdminPopup);