
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../assets/sass/popup.sass';
import CloseImg from '../assets/img/close.png';
import Flip from './Flip'
import { L } from '../api/Core'
import {
    Login,
    Basket,
    StatusMessage,
    AddProduct,
    Order,
    Admin,
    EditText,
    Opt
} from './Popups/index'

const popupContent = {
    login: {
        Title: "Вход",
        Component: Login
    },
    addproduct: {
        Title: "Добавление",
        Component: AddProduct
    },
    edittext: {
        Title: "Правка текста",
        Component: EditText
    },
    admin: {
        Title: "Админ-панель",
        Component: Admin
    },
    basket: {
        Title: L("ui_basket"),
        Component: Basket
    },
    order: {
        Title: L("ui_order"),
        Component: Order
    },
    statusMessage: {
        Title: "",
        Component: StatusMessage
    },
    opt: {
        Title:  L("ui_opt_title"),
        Component: Opt
    },
    default: {
        Title: "",
        Component: () => null
    }
}

const Popup = props => {
    const popupRef = useRef(null);
    const popup   = props.popup
    const { Title = "", Component } = popupContent[popup.type || "default"]


    const Payload = popup.type === "statusMessage" ?
        (<div className={"Popup _statusMessage " + (popup.state ? 'active' : "")}>
            <Component 
                opts={popup.opts} 
                flip={props.flip}
                close={props.close}
            />
        </div>) 
        :
        (<div ref={popupRef} className={"Popup " + (popup.state ? 'active' : "")}>
                <div className="PopupHeader">
                    <span>{Title}</span>
                    <img src={CloseImg} alt="" className="ClosePopup" 
                        onClick={() => props.close()}
                    />
                </div>
                <div className="Payload">
                    <Component
                        opts={popup.opts} 
                        flip={props.flip}
                        close={props.close}
                    />
                </div>

            </div>)
    return (
        <div id="MainPopup" className={"PopupWrapper " + (props.popup.state ? 'active ' : "")}>
            <Flip flipped={props.popup.flipped}>
                { Payload }
                { Payload }
            </Flip>
        </div>
    )
}

function MapStateToProps(state) {
    return {
        popup: state.popup,
    }
}

function MapDispatchToProps(dispatch) {
    return bindActionCreators({
        flip:  (type) => {
            return {
                type: "POPUP_FLIP",
                payload: type
            }
        },
        close: () => {
            return {
                type: "POPUP_CLOSE"
            }
        }
    }, dispatch)
}

export default connect(MapStateToProps, MapDispatchToProps)(Popup);