import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popup, L, getUserLanguage } from '../api/Core'

import '../assets/sass/app.sass';

const Products = props => {
  return (
    <div className="Products">
        {props.products.sort((a, b) => {
                    if (a.added < b.added) {
                        return 1;
                    }
                    if (a.added > b.added) {
                        return -1;
                    }
                    return 0;
                    }).map(el => (
            <div key={"PRD"+ el.id} className="Product">
                <div className="Img">
                    <img src={el.img} alt={el.name} />
                </div>
                <div className="Details">
                    <h2>{el["name_" + getUserLanguage().toLowerCase()]}</h2>
                    <div className="PriceGroup">
                      <h3 className="PriceLabel"><span className="Big">{el.price}</span>{L("ui_hrn")}</h3>
                      <h3 className="OptLabel"> {L("ui_wholesale")}: <span>{el.optprice} {L("ui_hrn")}</span><br /><i>{L("ui_from")} {el.optfrom} {L("ui_pcs")}</i></h3>
                    </div>
                    <p>
                      {el["details_" + getUserLanguage().toLowerCase() ].split("|").map((el, i) => {
                          return <span key={"LN" + i + "P" + el.id}>{el} <br/></span>
                      })}
                    </p>
                    <button onClick={() => {
                        props.addToBasket({
                            id: el.id,
                            amount: 1
                        })
                        Popup.open({type: "basket"})
                    }} className="btn">{L("ui_buy")}</button>
                </div>
            </div>
        ))}
    </div>
);
}

function MapStateToProps(state) {
  return {
    appState: state.appState,
    products: state.products
  }
}

function MapDispatchToProps(dispatch) {
  return bindActionCreators({
    init: (data) => {
      console.log('init data', data)
      return {
        type: 'INIT',
        payload: data
      }
    },
    addToBasket: (data) => {
      return {
        type: 'BASKET_PLUS',
        payload: data
      }
    }
  }, dispatch)
}

export default connect(MapStateToProps, MapDispatchToProps)(Products);