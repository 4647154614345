import { createStore } from 'redux';
import React from "react"
import root from '../reducers/index';
import { getCookie, setCookie } from "../helpers/Cookie"

const store = createStore(root)

const AD = (type, payload) => {
  store.dispatch({type, payload})
}

const delay = (ms) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  })
)

function getFirstBrowserLanguage() {
        var nav = window.navigator,
            browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
            i,
            language,
            len,
            shortLanguage = null;

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            for (i = 0; i < nav.languages.length; i++) {
                language = nav.languages[i];
                len = language.length;
                if (!shortLanguage && len) {
                    shortLanguage = language;
                }
                if (language && len>2) {
                    return language;
                }
            }
        }

        // support for other well known properties in browsers
        for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
            language = nav[browserLanguagePropertyKeys[i]];
            len = language.length;
            if (!shortLanguage && len) {
                shortLanguage = language;
            }
            if (language && len > 2) {
                return language;
            }
        }

        return shortLanguage;
    }

  const getLanguage = () => {
    let FBL = getFirstBrowserLanguage();
    if(!FBL) return "UA"
    if(FBL.split("-").length) {
      if(FBL.split("-")[0].toLowerCase() === "ru") return "RU"
      if(FBL.split("-")[0].toLowerCase() === "uk") return "UA"
    } else {
      if(FBL.toLowerCase() === "ru") return "RU"
      if(FBL.toLowerCase() === "uk") return "UA"
    }
    return "EN"
  }

const Dict = {
  partners_title: {
    EN: "Partners",
    UA: "Нашi партнери",
    RU: "Наши партнеры"
  },
  partners_text: {
    EN: "Today we supply our products to the largest Ukrainian construction materials networks, such as Leroy Merlin, Epicenter and others. We strive for development and expansion so that as many consumers as possible have the opportunity to feel the quality and efficiency of using our products!",
    UA: "Сьогодні ми постачаємо нашу продукцію до найбільших українських мереж будівельних матеріалів, таких як “Леруа Мерлен”, “Епіцентр” та інші. Ми дуже прагнемо до розвитку та розширення, аби якогмога більше споживачів мали можливість відчути якість та ефективність використання нашої продукції!",
    RU: "Сегодня мы поставлем нашу продукцию в крупнейшие украинские сети строительных материалов, таких как “Леруа Мерлен”, “Эпицентр” и другие. Мы очень стремимся к развитию и расширению, чтобы как можно больше потребителей почувствовали качество и эффективность использования нашей продукции!",
  },
  features_title: {
    EN: "Our features",
    UA: "Нашi переваги",
    RU: "Наши преимущества"
  },
  products_title: {
    EN: "Our products",
    UA: "Наша продукцiя",
    RU: "Наша продукция"
  },
  contacts_title: {
    EN: "Contacts",
    UA: "Контакти",
    RU: "Контакты"
  },
  feedback_title: {
    EN: "Feedback",
    UA: "Зворотний зв'язок",
    RU: "Обратная связь"
  },
  feature_1_h: {
    EN: "Reliable",
    UA: "Надійний",
    RU: "Надежный"
  },
  feature_2_h: {
    EN: "Water-resistant",
    UA: "Вологостiйкий",
    RU: "Влагостойкий"
  },
  feature_3_h: {
    EN: "Eco-Friendly",
    UA: "Екологічно чистий",
    RU: "Экологически чистый"
  },
  feature_4_h: {
    EN: "Convenient",
    UA: "Зручний",
    RU: "Удобный"
  },
  feature_1_t: {
    EN: "High density provides reliable protection against mechanical damage",
    UA: "Висока щільність забезпечує надійний захист від механічних та інших пошкоджень",
    RU: "Высокая плотность обеспечивает надежную защиту от механических и прочих повреждений"
  },
  feature_2_t: {
    EN: "Resists moisture absorption",
    UA: "Завдяки спеціальним гiдрофобним добавкам протистоïть вбиранню вологи",
    RU: "Благодаря специальным гидрофобным добавкам противостоит впитыванию влаги"
  },
  feature_3_t: {
    EN: "Made from waste paper. Fully recyclable",
    UA: "Виготовлений з макулатури. Підлягає повній переробці",
    RU: "Изготовлен из макулатуры. Подлежит полной переработке"
  },
  feature_4_t: {
    EN: "Easy to operate and store",
    UA: "Зручний в роботі та зберіганні",
    RU: "Удобен в работе и хранении"
  },
  form_name: {
    EN: "Your name",
    UA: "Ваше iм'я",
    RU: "Ваше имя"
  },
  form_tel: {
    EN: "Phone Number",
    UA: "Номер телефону",
    RU: "Номер телефона"
  },
  form_msg: {
    EN: "Message",
    UA: "Повiдомлення",
    RU: "Сообщение"
  },
  form_err: {
    EN: "Error",
    UA: "Помилка",
    RU: "Ошибка"
  },
  form_send: {
    EN: "Send",
    UA: "Вiдiслати",
    RU: "Отправить"
  },
  ui_buy: {
    EN: "Buy",
    UA: "Придбати",
    RU: "Купить"
  },
  ui_hrn: {
    EN: "UAH",
    UA: "ГРН.",
    RU: "ГРН."
  },
  ui_wholesale: {
    EN: "WHSL",
    UA: "ОПТ",
    RU: "ОПТ"
  },
  ui_showproducts: {
    EN: "Products",
    UA: "Продукція",
    RU: "Продукция"
  },
  ui_sale: {
    EN: "discount",
    UA: "знижка",
    RU: "скидка"
  },
  menu_main: {
    EN: "Home",
    UA: "Головна",
    RU: "Главная"
  },
  menu_contact: {
    EN: "Contact",
    UA: "Контакти",
    RU: "Контакты"
  },
  menu_products: {
    EN: "Products",
    UA: "Продукцiя",
    RU: "Продукция"
  },
  ui_continue_shopping: {
    EN: "Hide Basket",
    UA: "Згорнути кошик",
    RU: "Свернуть корзину"
  },
  ui_place_order: {
    EN: "Сheckout",
    UA: "Оформити замовлення",
    RU: "Оформить заказ"
  },
  ui_order: {
    EN: "Сheckout",
    UA: "Замовлення",
    RU: "Заказ"
  },
  ui_from: {
    EN: "FROM",
    UA: "ВIД",
    RU: "ОТ"
  },
  ui_pcs: {
    EN: "PCS",
    UA: "ШТ",
    RU: "ШТ"
  },
  address: {
    EN: "Kyiv, Volgogradskaya Str.4, p.7",
    UA: "Київ, Волгоградська, буд.4, к.7",
    RU: "Киев, Волгоградская, д.4, к.7"
  },
  order_success: {
    EN: "Order created!",
    UA: "Замовлення прийняте!",
    RU: "Заказ успешно оформлен!"
  },
  manager_note: {
    EN: "Our manager will contact you soon",
    UA: "Незабаром наш менеджер зателефонує",
    RU: "Скоро с Вами свяжется наш менеджер"
  },
  status_close: {
    EN: "Close",
    UA: "Закрити",
    RU: "Закрыть"
  },
  order_confirm: {
    EN: "Confirm Order",
    UA: "Пiдтвердити",
    RU: "Подтвердить"
  },

  order_name: {
    EN: "Reciever's Full Name",
    UA: "ПIБ одержувача",
    RU: "ФИО получателя"
  },
  order_name_err: {
    EN: "Invalid Full Name",
    UA: "Помилка",
    RU: "Ошибка"
  },
  form_tel_err: {
    EN: "Invalid Phone Number",
    UA: "Помилка",
    RU: "Ошибка"
  },
  form_email_err: {
    EN: "Invalid Email",
    UA: "Помилка",
    RU: "Ошибка"
  },
  form_adress: {
    EN: "Delivery Address",
    UA: "Адреса доставки",
    RU: "Адрес доставки"
  },
  form_adress_err: {
    EN: "Invalid Address",
    UA: "Помилка",
    RU: "Ошибка"
  },
  ui_best_q: {
    EN: "KARTEX IS THE RELIABLE TEMPORARY FLOOR PROTECTION!",
    UA: <>КАРТЕКС – малярний картон для захисту підлоги.<br />КАРТЕКС – це надійний захист.</>,   
    RU: <>КАРТЕКС – малярный картон для защиты пола.<br />КАРТЕКС – ваша надёжная защита!</>
  },
  ui_basket: {
    EN: "Basket",
    UA: "Кошик",
    RU: "Корзина"
  },
  ui_opt: {
    EN: "Contact us and get individual conditions when buying from 1 pallet",
    UA: "Зв'яжіться з нами і отримаєте індивідуальні умови при покупці від 1 палети",
    RU: "Свяжитесь с нами и получите индивидуальные условия при покупке от 1 паллеты"
  },
  ui_opt_title: {
    EN: "Wholesalers",
    UA: "Оптовикам",
    RU: "Оптовикам"
  }
}


const getUserLanguage = () => {
  let lang = getCookie("lang");
  if (!lang || (lang !== "RU" && lang !== "UA" && lang !== "EN")) {
    lang = getLanguage()
    setCookie("lang", lang, 999)
  }
  return lang
}

const L = (token) => {
  return token in Dict ? Dict[token][getUserLanguage()] : "[!] " + token
}

const callAPI = (target, body = {}) => fetch(target, {
  method: 'post',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
}).then(res => {
  return res.json();
}).catch(err => {
  console.log("serror", "An error occured during the request.")
});



const User = {
  logout: () => callAPI("/logout").then(res => {
    res.success && AD("USER_LOGOUT")
    return res
  }),
  login: data => callAPI("/login", data).then(res => {
    res.success && AD("USER_LOGIN", res.user)
    return res
  }),
  addProduct: data => callAPI("/addproduct", data).then(res => {
    res.success && AD("PRODUCT_ADD", res.product)
    console.log(res)
    return res
  }),
  editText: data => callAPI("/edittext", data).then(res => {
    alert(res.success ? "сохранено успешно" : "ошибка при сохранении!")
    return res
  }),
  updateProduct: data => callAPI("/changeproduct", data).then(res => {
    res.success && AD("PRODUCT_CHANGE", res.product)
    console.log(res)
    return res
  }),
  deleteProduct: data => callAPI("/deleteproduct", {prodID: data}).then(res => {
    res.success && AD("PRODUCT_DELETE", res.id)
    console.log(res)
    return res
  }),
}


// payload:  { type, [opts] }
const Popup = {
  open:  (payload) => AD("POPUP_OPEN", payload),
  close: () => AD("POPUP_CLOSE"),
  flip:  (payload) => {
    AD("POPUP_FLIP")
    delay(200).then(() => AD("POPUP_OPEN", payload))
  },
  alert: (statusType) => {
    AD("POPUP_FLIP")
    delay(200).then(() => AD("POPUP_OPEN", {type: "statusMessage", opts: {statusType}}))
    delay(3500).then(() => AD("POPUP_CLOSE")
    )
  }
}

const payOrder = data => callAPI("/createinvoice", data).then(res => {
  // res.success && AD("PRODUCT_CHANGE", res.product)
  console.log(res)
  return res
})

const sendFeedback = data => callAPI("/sendfeedback", data).then(res => {
  // res.success && AD("PRODUCT_CHANGE", res.product)
  console.log(res)
  return res
})

const INIT = () => {
  return callAPI("/").then(data => {
    console.log("INIT DATA", data)
      AD("INIT", data)
    if(data.user) {
      // AD("POPUP_OPEN", {type: "admin"})
    } else {
      if(window.location.pathname === "/admin") AD("POPUP_OPEN", {type: "login"})
    }
  })
}

INIT()


export {
  store,
  INIT,
  Popup,
  User,
  payOrder,
  sendFeedback,
  AD,
  L,
  getUserLanguage
}