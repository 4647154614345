import React, {useState, useLayoutEffect, useRef} from 'react';


const Select = props => {
// accepts array [[title, id], [title, id], ... ]
    const {id, from, className = "", onChange, _list = {}} = props;
    const [curr, setCurr] = useState(from[0][1])
    const Indicator = useRef(null);

    useLayoutEffect(() => {
        const [w, l] = [_list[curr].offsetWidth, _list[curr].offsetLeft]
        Indicator.current.style.transform = `translateX(${l}px) scaleX(${w / 100})`;
        setTimeout(() =>{Indicator.current.style.transitionDuration = `.3s`}, 100)
    }, [curr]);

    return (
        <div className={"InpSelect " + className }>
            {from.map(el => (
                <div
                    key={id + el[1]}
                    className={"Option " + (el[1] === curr ? "Active" : "")} 
                    onClick={() => setCurr(el[1]) || onChange(el[1])}
                    ref={ref => _list[el[1]] = ref}
                >
                    {el[0]}
                </div>
            ))}
            <div ref={Indicator} className="Indicator"></div>
        </div>
    )
}


const Dropdown = props => {
    const [opened, setOpened] = useState(false)

    const toggleValues = opt => {
        const v = (props.values.some(el => el === opt[1]) ? [...props.values].filter(el => el !== opt[1]) : [...props.values, opt[1]])
        props.onChange(v)
    }
      
    function findNode(e) {
        let a = e.target
        while (a) {
            if (a.id === props.id) return true
            a = a.parentNode;
        }
        setOpened(false)
    }

    useLayoutEffect(() => {
        window.addEventListener("click", findNode)
        return () => window.removeEventListener("click", findNode)
    }, [])

    return (
        <div id={props.id} className={"Dropdown " + (opened ? "active " : " ") + props.className}>
            <span onClick={() => setOpened(!opened)}>{props.placeholder}</span> <i className="Caret fas fa-caret-down"></i>
            <div className="Indicator"></div>
            <div className="DropdownList">
                {props.options.map(opt => (
                    <div 
                        className={"DropdownOption " + (props.values.some(el => el === opt[1]) ? "checked" : "")} 
                        onClick={() => toggleValues(opt)}
                        key={props.id + opt[1]}
                    >
                    <div className="DropdownCheckBox"><i className="fas fa-check"></i></div><span>{opt[0]}</span></div>
                ))}
            </div>
        </div>
    )
}

const Input = props => {
    const inputRef = useRef();
    const [focus, setFocus] = useState(Boolean(props.defaultVal));
    return ( 
        <div className={"Input " + (focus ? "focused " : " ") + (props.className || "") }>
            <input
                required={true}
                type={props.type || "text"}
                ref={inputRef}
                name={props.name}
                disabled={props.disabled}
                defaultValue={props.defaultVal || ""}
                autoComplete = "off"
                autoCorrect = "off"
                autoCapitalize = "off"
                spellCheck = "false"
                onChange={
                    (e) => props.onChange([props.name, e.target.value])
                }
                onFocus = {
                    () => setFocus(true)
                }
                onBlur={
                    (e) => {
                        inputRef.current.value || setFocus(false)
                        props.onBlur([props.name, e.target.value])
                    }   
                }
            />
            <label 
                htmlFor={props.name}
            >
            {props.placeholder} <span className="InputError"> {props.error && props.error[0]}</span>
            </label>
        </div>
    )
};

const Textarea = props => {
    const inputRef = useRef();
    const [focus, setFocus] = useState(Boolean(props.defaultVal));
    return ( 
        <div style={{height: "100px"}} className={"Textarea " + (focus ? "focused " : " ") + (props.className || "") }>
        
            <textarea
                style={{maxHeight: "60px"}}
                rows="3"
                required={true}
                type={props.type || "text"}
                ref={inputRef}
                name={props.name}
                defaultValue={props.defaultVal || ""}
                autoComplete = "off"
                autoCorrect = "off"
                autoCapitalize = "off"
                spellCheck = "false"
                onChange={
                    (e) => props.onChange([props.name, e.target.value])
                }
                onFocus = {
                    () => setFocus(true)
                }
                onBlur={
                    (e) => {
                        inputRef.current.value || setFocus(false)
                        props.onBlur([props.name, e.target.value])
                    }   
                }
            />
            <label 
                htmlFor={props.name}
            >
            {props.placeholder} <span className="InputError"> {props.error && props.error[0]}</span>
            </label>
        </div>
    )
};


const Select2 = props => {
    const [focus, setFocus] = useState(false);
    const [value, setValue] = useState("");
    const onChange = (val) => {
        setValue(val)
        props.onChange([props.name, val])
    }
    return (
        <div onClick={() => setFocus(!focus)} className={"Select " + (value ? "selected " : " ") + ( focus ? "active" : "" ) }>
            <i className="fas fa-chevron-down DropdownArr"></i>
            <label>{props.placeholder} <span className="InputError">{props.error && props.error[0]}</span></label> 
            <span className="DropdownValue">{value}</span>
            <ul className="Dropdown">
                {props.options.map(opt => (
                    <li 
                        onClick={() => onChange(opt.value)}
                        className={opt.value === value ? "selected" : ""}
                    >
                    { opt.icon && <i className={"OptIcon far fa-" + opt.icon}></i>} {opt.title}
                    </li>
                ))}
            </ul>
        </div>
    )
};

const SubmitButton = props => {
    return (
        <button 
            className={"SubmitButton " + props.className} 
            onClick={props.onClick}
        >
            <span className="SubmitTitle">{props.submitTitle}</span>
            <span className="LoadingTitle">Loading...</span>
        </button>
    )
}

const CheckBox = props => {
    const [value, setValue] = useState(false);
    return (
        <div className="CheckBoxWrapper">
            <div 
                className={"CheckBox " + (value ? "active" : "")}
                onClick={() => {
                    props.onChange([props.name, !value])
                    setValue(!value)
                }}
            >
                <input 
                    type="checkbox" 
                    checked={value}
                    name={props.name}
                />
                <span className="CheckBoxToggle"></span>
                <span className="CheckBoxTitle" style={{color: props.error && props.error[0] ? "#ff5252" : ""}}>{props.placeholder}</span>
            </div>
        </div>
        
    )
}



export {
    Select,
    Input,
    Select2,
    SubmitButton,
    CheckBox,
    Dropdown,
    Textarea
}
