import {getCookie , setCookie} from "../helpers/Cookie"

// setCookie("basket", JSON.stringify({}))

export default function (state = {}, action) {
    switch (action.type) {
        case "BASKET_PLUS":
            const PlusState = {...state,
                [action.payload.id]: +(action.payload.id in state ? state[action.payload.id] + action.payload.amount : action.payload.amount)
            }
            setCookie("basket", JSON.stringify(PlusState))
            return PlusState
        case "BASKET_MINUS":
            const MinusState = {...state,
                [action.payload.id]: +(action.payload.id in state ? Math.max(state[action.payload.id] - action.payload.amount, 1) : 1)
            }
            if(state[action.payload.id] - action.payload.amount === 0) delete MinusState[action.payload.id]
            setCookie("basket", JSON.stringify(MinusState))
            return MinusState
        case "BASKET_SET":
            const setState = {...state,
                [action.payload.id]: +action.payload.amount
            }
            if(+action.payload.amount === 0) delete setState[action.payload.id]
            setCookie("basket", JSON.stringify(setState))
            return setState
        case "BASKET_CLEAR":
            setCookie("basket", JSON.stringify({}))
            return {}
        case "INIT":
            let cookieState = getCookie("basket");
            if (cookieState === null) {
                setCookie("basket", JSON.stringify({}))
                return {}
            } else {
                const filterState = JSON.parse(cookieState)
                Object.keys(filterState).forEach(key => {
                    if (!action.payload.products.some(el => el.id === key)) delete filterState[key]
                })
                setCookie("basket", JSON.stringify(filterState))
                return filterState
            }
        default:
            return state
    }

}