const initial = {
    username: null,
    id: null,
    balance: null
}

export default function (state = {...initial, loaded: false}, action) {
    switch (action.type) {
        case 'USER_LOGIN':
            return {...state, ...action.payload}
        case 'USER_LOGOUT':
            return {...initial, loaded: true}
        case 'INIT':
            return action.payload.user ? {
                ...action.payload.user, 
                loaded: true
            } : {
                ...state,
                loaded: true
            }
        default:
            return state
    }

}