import React from 'react';
import { L } from '../../api/Core'


const OptPopup = props => {
    return (
        <div className="BasketPopup ALTT">
            <img className="opt-img" src="/pallet.png" alt="" />
            <p>{L("ui_opt")}</p>
            <div className="buttonsss">
                <a href="https://wa.me/380953301110" className="btn">WhatsApp</a>
                <a href="https://t.me/kartexCEO" className="btn">Telegram</a>
            </div>
        </div>
    )
}

export default OptPopup