export default function (state = {
    loaded: false,
    searchOpened: false,
    texts: {
        contact: "",
        main: "",
        partner: ""
    }
}, action) {
    switch (action.type) {
        case "INIT":
            return {...state, loaded: true, texts: action.payload.texts}
        
        default:
            return state
    }

}