export default function (state = [], action) {
    switch (action.type) {
        case "INIT":
            return action.payload.products || []
        case "PRODUCT_ADD":
            return [action.payload, ...state]
        case "PRODUCT_DELETE":
            return [...state].filter(el => el.id !== action.payload)
        case "PRODUCT_CHANGE":
            return [...[...state].filter(el => el.id !== action.payload.id), action.payload]
        default:
            return state
    }

}