import React from 'react';
import '../assets/sass/header.sass';

export default props => {

  return (
    <div className={"MobileMenuWrapper " + (props.status ? "active" : "")}>
      <div className="MobileMenu">
        <div className="Controls">
          <i className="fal fa-times-circle"></i>
        </div>
        {props.menu.map(item => (
          <div>{item.name}</div>
        ))}
      </div>
    </div>
  )
}