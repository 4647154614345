import React, { useState, useEffect } from 'react';
import {
    Input,
    Select2,
    SubmitButton,
    CheckBox,
    Textarea
} from './Input'
import validate from 'validate.js'
import '../assets/sass/form.sass'

const Validate = (input, validator, dataSet = {}) => {
    let errors = validate.single(input, validator, dataSet)
    return errors
}

export const Form = props => {
    const [formData, updateFormData] = useState({})
    const [loading,  setLoading]     = useState(false)
    const [buttonActive, setButtonActive] = useState(false)

    useEffect(() => {
        const initial = {};
        Object.keys(props.form).forEach(input => {
            initial[input] = {
                ...props.form[input],
                error: null,
                value: props.form[input].default || ""
            }
        })
        updateFormData(initial)
    },[])

    useEffect(() => {
        setButtonActive(Object.keys(formData).every(key => (
            formData[key].error !== null && !formData[key].error
        ) && !loading))
    })


    validate.validators.compare = (input, options) => {
        if (formData[options].value !== input) return "noMatch";
    };

    validate.validators.isTrue = (input, options) => {
        if (!input) return "error";
    };

    const setErrors = (errors) => {
        let _form = {...formData};
        Object.keys(errors).forEach(key => {
            _form[key].error = [errors[key]]
        })
        updateFormData(_form)
    }

    const onChange = action => {
        let _form = {...formData};
        _form[action[0]].value = action[1]
        const validator = formData[action[0]].validate;
        _form[action[0]].error = Validate(action[1], validator);
        updateFormData(_form)
    }

    const onBlur = action => {
        const newFieldData = {...formData};
        const validator = formData[action[0]].validate;
        newFieldData[action[0]].error = Validate(action[1], validator);
        updateFormData(newFieldData)
    }

    const validateAll = () => {
        const newFieldData = {...formData};
        Object.keys(newFieldData).forEach(key => {
            const validator = newFieldData[key].validate;
            const err = Validate(newFieldData[key].value, validator);
            newFieldData[key].error = err
        })
        updateFormData(newFieldData)
    }

    const parseFormData = () => {
        let out = {};
        Object.keys(formData).forEach(key => {
            out[key] = formData[key].value;
        })
        return out
    }

    const onSubmit = (formData, callback) => {
        setLoading(true)
        callback(formData)
            .then(res => {
                if (!res.success) setErrors(res.errors)
                setLoading(false)
                // set errors or display success.
            })
    }
    
    return (
        <div className="FormWrapper">
            <input type="hidden" value="disableautofill"/>
            {
                Object.keys(formData).map(input => {
                    const thisField = formData[input]
                    return thisField.type === "select" ? 
                        <Select2 
                            className={thisField.class}
                            type={thisField.type}
                            options={thisField.options} 
                            onChange={onChange}
                            key={props.id+input}
                            name={input}
                            error={thisField.error}
                            placeholder={thisField.placeholder}
                        /> 
                        : thisField.type === "checkbox" ?
                        <CheckBox
                            className={thisField.class}
                            type={thisField.type}
                            key={props.id+input}
                            name={input}
                            onChange={onChange} 
                            onBlur={onBlur}
                            error={thisField.error}
                            placeholder={thisField.placeholder}
                        />
                        : thisField.type === "textarea" ?
                        <Textarea
                            className={thisField.class}
                            type={thisField.type}
                            key={props.id+input}
                            height={props.height}
                            name={input}
                            onChange={onChange} 
                            onBlur={onBlur}
                            defaultVal={thisField.default}
                            error={thisField.error}
                            placeholder={thisField.placeholder}
                        />
                        : thisField.type === "hidden" ?
                        <></>
                        : <Input
                            className={thisField.class}
                            type={thisField.type}
                            key={props.id+input}
                            name={input}
                            onChange={onChange} 
                            onBlur={onBlur}
                            defaultVal={thisField.default}
                            error={thisField.error}
                            placeholder={thisField.placeholder}
                        /> 
                })
            }
            <div className="SubmitButtons">
            {
                props.actions.map(el => (
                    <SubmitButton
                        key={el.title + props.id}
                        className={(el.validate ? buttonActive ? "active " : " " :  "active " ) + ( loading ? "loading " : "") + (el.class ? el.class : "") }
                        onClick={() => {
                            el.validate && validateAll()
                            el.validate ? (buttonActive && onSubmit(parseFormData(), el.callback)) : onSubmit(parseFormData(), el.callback)
                        }}
                        submitTitle={el.title}
                    />
                ))
            }
            </div>
        </div> 
    )
}