import React from 'react';
import { Popup, User } from '../../api/Core'
import { Form } from '../Form'



export default props => {
    return (
        <div className="AdminPopup">
            <Form id={"editTextForm"} form={{
                prodName: {
                    placeholder: "Название товара",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 30
                        },
                    }
                },
                prodImg: {
                    placeholder: "Ссылка на изображение",
                    validate: {
                        presence: true,
                        url: true
                    }
                },
                prodPrice: {
                    placeholder: "Цена",
                    class: "split3",
                    validate: {
                        presence: true,
                        numericality: {
                            onlyInteger: true,
                            greaterThan: 1
                        }
                    }
                },
                prodOptPrice: {
                    placeholder: "Цена (опт)",
                    class: "split3",
                    validate: {
                        presence: true,
                        numericality: {
                            onlyInteger: true,
                            greaterThan: 1
                        }
                    }
                },
                prodOptFrom: {
                    placeholder: "Опт от шт.",
                    class: "split3",
                    validate: {
                        presence: true,
                        numericality: {
                            onlyInteger: true,
                            greaterThan: 1
                        }
                    }
                },
                prodDesc: {
                    placeholder: "Описание товара ( | – перевод строки )",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 100
                        },
                    }
                },
            }} 
            actions={[
                {
                    title: "Добавить товар",
                    class: "alt",
                    validate: true,
                    callback: (formData) => {
                        return new Promise((resolve, reject) => {
                            User.addProduct(formData)
                            .then(res => {
                                console.log(res)
                                if(!res.success) return resolve(res)
                                Popup.flip({
                                    type: "admin",
                                })
                                return resolve(res)
                            })
                        })
                    }
                }
            ]}
            />
            <p style={{color: "white", cursor: "pointer", fontFamily: "HelveticaBold", paddingBottom: "30px"}} onClick={() => { Popup.flip({
                type: "admin"
            })}}>Вернуться назад</p>
    </div>)
}