import React from 'react';
import Slider from "react-slick";
import '../assets/sass/main-slider.sass';

export default props => {
    const _s = React.createRef();

    const opts = {
        dots: false,
        infinite: true,
        autoplay: {},
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        variableWidth: true,
        centerMode: true,
        ref: _s,
    };

    return (
        <div className="MainSlider">
            <Slider {...opts}>
                {props.children}
            </Slider>
        </div>
    )
}