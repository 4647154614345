import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from './Header'
import MainSlider from './MainSlider'
import Preloader from './Preloader'
import { Form } from './Form'
import Admin from './Admin'
import PopupComponent from './Popup'
import { sendFeedback, L, getUserLanguage, AD } from '../api/Core'
import Products from './Products'

import  { ReactComponent as F1 } from "../assets/svg/f1.svg"
import  { ReactComponent as F2 } from "../assets/svg/f2.svg"
import  { ReactComponent as F3 } from "../assets/svg/f3.svg"
import  { ReactComponent as F4 } from "../assets/svg/f4.svg"

import  { ReactComponent as I1 } from "../assets/svg/tel.svg"
import  { ReactComponent as I2 } from "../assets/svg/fax.svg"
import  { ReactComponent as I3 } from "../assets/svg/mail.svg"
import  { ReactComponent as I4 } from "../assets/svg/loc.svg"

import p1 from "../assets/img/p1.png"
import p2 from "../assets/img/p2.png"
import p3 from "../assets/img/p3.png"
import p4 from "../assets/img/p4.png"
import p5 from "../assets/img/p5.png"

import logo2 from "../assets/img/logo2.png"

import '../assets/sass/app.sass'

const App = props => {
  const lang = getUserLanguage()
  document.title = lang === "RU" ? "Картекс – это защита полов наилучшего качества!" : lang === "EN" ? "KARTEX is the best quality floor protection!" : "Картекс — це захист підлоги найвищої якості!";
  
  if(window.location.pathname === "/admin" && props.user.id) {
    return <Admin />
}
  useEffect(() => {
    if(window.location.pathname !== "/admin") setTimeout(() => AD("POPUP_OPEN", {type: "opt"}), 10 * 1000)
  }, [])


  return (
    <>
      <div className={"App " + (props.popup.state ? "blurred" : "")}>
        <Header basket={props.basket} />
        <section className="MainBlock">
          <div className="ButtonBlock">
            <h1><span className="gagalin">{L("ui_best_q")}</span></h1>
            {props.appState.loaded && <h4>{props.appState.texts.main[lang].split("|").map(el => <>{el}<br /></>)}</h4>}
            <button onClick={() => window.location.hash = "#prod"} className="btn">{L("ui_showproducts")}</button>
          </div>
        </section>
        <section>
          <div className="FeaturesBlock">
            <h1>{L("features_title")}</h1>
            <div className="Features">
              <div className="Feature">
                <F1 />
                <h3>{L("feature_1_h")}</h3>
                <p>{L("feature_1_t")}</p>
              </div>
              <div className="Feature">
                <F2 />
                <h3>{L("feature_2_h")}</h3>
                <p>{L("feature_2_t")}</p>
              </div>
              <div className="Feature">
                <F3 />
                <h3>{L("feature_3_h")}</h3>
                <p>{L("feature_3_t")}</p>
              </div>
              <div className="Feature">
                <F4 />
                <h3>{L("feature_4_h")}</h3>
                <p>{L("feature_4_t")}</p>
              </div>  
            </div>
          </div>
        </section>
        <section className="VideoBlockSection">
          <div className="VideoBlock">
            <div className={"Video " + getUserLanguage() }>

            </div>
          </div>
        </section>
        <section>
          <div className="ProductsBlock" id="prod">
            <h1>{L("products_title")}</h1>
            <Products />
          </div>
        </section>
        <section>
          <div className="PartnersBlock">
            <h1>{L("partners_title")}</h1>
            {props.appState.loaded && <h4>{props.appState.texts.partner[lang].split("|").map(el => <>{el}<br /></>)}</h4>}
            <MainSlider>
              <div className="_Slide">
                <img src={p1} alt="" />
              </div>
              <div className="_Slide">
                <img src={p2} alt="" />
              </div>
              <div className="_Slide">
                <img src={p3} alt="" />
              </div>
              <div className="_Slide">
                <img src={p4} alt="" />
              </div>
              <div className="_Slide">
                <img src={p5} alt="" />
              </div>
            </MainSlider>
          </div>
        </section>
        <section id="cont" className="ContactsSection">
          <div className="ContactsWrapper">
            <div className="FooterLogoWrapper"><img src={logo2} alt="Картекс" /></div>
            <div className="Contacts">
              <h3>{L("contacts_title")}</h3>
              <p><I1 /> {props.appState.texts.contact.tel}</p>
              <p><I2 /> {props.appState.texts.contact.fax}</p>
              <p><I3 /> {props.appState.texts.contact.mail}</p>
              <p><I4 /> {L("address")}</p>
            </div>
            <div className="Feedback">
              <h3>{L("feedback_title")}</h3>
              <Form id={"addProdForm"} form={{
                orderName: {
                    placeholder: L("form_name"),
                    validate: {
                        presence: true,
                        length: {
                          minimum: 2,
                          maximum: 30,
                          message: L("form_err")
                        },
                    }
                },
                orderTel: {
                    placeholder: L("form_tel"),
                    class: "split2",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 16,
                            message: L("form_err")
                        },
                    }
                },
                orderEmail: {
                    placeholder: "Email",
                    class: "split2",
                    validate: {
                        presence: true,
                        email: {
                            message: L("form_err")
                        }
                    }
                },
                orderMsg: {
                    placeholder: L("form_msg"),
                    type: "textarea",
                    validate: {
                        presence: true,
                        length: {
                            minimum: 3,
                            maximum: 100,
                            message: L("form_err")
                        },
                    }
                },
            }} 
            actions={[
                {
                    title: L("form_send"),
                    class: "alt",
                    validate: true,
                    callback: (formData) => {
                        return new Promise((resolve, reject) => {
                            sendFeedback(formData)
                            .then(res => {
                                if(!res.success) return resolve(res)
                                return resolve(res)
                            })
                        })
                    }
                }
            ]}
            />
            </div>
          </div>
          <h5>Copyright © 2019 Kartex. All rights reserved.</h5>
        </section>
      </div>
    <Preloader />
    <PopupComponent />
    </>
);
}

function MapStateToProps(state) {
  return {
    appState: state.app_state,
    user: state.user,
    basket: state.basket,
    popup: state.popup
  }
}

function MapDispatchToProps(dispatch) {
  return bindActionCreators({
    init: (data) => {
      console.log('init data', data)
      return {
        type: 'INIT',
        payload: data
      }
    },
    PushFM: (data) => {
      return {
        type: 'FM_PUSH',
        payload: data
      }
    }, 
    
  }, dispatch)
}

export default connect(MapStateToProps, MapDispatchToProps)(App);